<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ title }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-widget4">
            <div
                v-for="exam in items"
                :key="exam.schoolYear"
                class="kt-widget4__item"
            >
                <span class="kt-widget4__icon">
                    <span
                        v-b-tooltip.hover="'Performance Rating'"
                        class="kt-badge kt-badge--xl"
                        :class="`kt-badge--${exam.className}`"
                    >{{ exam.performanceRating }}</span>
                </span>
                <p class="kt-font-bold mt-3">
                    <span>{{ exam.schoolYear }}</span>
                    <br>
                    <span class="text-muted">Performance Level {{ exam.performanceLevel }}</span>
                </p>
                <span
                    v-if="exam.growth"
                    v-b-tooltip.hover.topright="'Growth Percentile'"
                    class="kt-widget4__number kt-font-info pull-right"
                    style="width: auto"
                > +{{ exam.growth }} </span>
                <span
                    v-b-tooltip.hover.rightbottom="'Scaled Score'"
                    class="kt-widget4__number"
                    :class="`kt-font-${exam.className}`"
                    style="width: auto"
                >
                    {{ exam.scaledScore }}
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: 'PerformanceLevels',
    props: {
        subject: {
            type: String,
            required: true,
        },
        evaluations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            items: [],
        };
    },
    computed: {
        title() {
            const v = this;
            if (v.subject == 'Reading') return 'English Language Arts';
            return 'Mathematics';
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;

            const keys = [];
            v.evaluations.forEach((ev) => {
                if (ev.bioCategory == `${v.subject} Assessment Result`) {
                    const key = parseInt(ev.schoolYear, 10);
                    if (keys.indexOf(key) == -1) keys.push(key);
                }
            });

            keys.sort().reverse();
            keys.forEach((key) => {
                let className = 'dark';
                const performanceLevel = v.evaluations.find((e) => e.bioTitle == `${v.subject} Performance Level` && e.schoolYear == key);
                const performanceRating = v.evaluations.find((e) => e.bioTitle == `${v.subject} Performance Rating` && e.schoolYear == key);
                const scaledScore = v.evaluations.find((e) => e.bioTitle == `${v.subject} Scaled Score` && e.schoolYear == key);
                const growth = v.evaluations.find((e) => e.bioTitle == `${v.subject} Growth Percentile` && e.schoolYear == key);

                if (performanceRating && parseFloat(performanceRating.bioValue) >= 0) className = 'danger';
                if (performanceRating && parseFloat(performanceRating.bioValue) >= 1) className = 'warning';
                if (performanceRating && parseFloat(performanceRating.bioValue) >= 2) className = 'primary';
                if (performanceRating && parseFloat(performanceRating.bioValue) >= 3) className = 'success';

                v.items.push({
                    className,
                    schoolYear: `${key} - ${key + 1}`,
                    performanceLevel: performanceLevel ? performanceLevel.bioValue : 0,
                    performanceRating: performanceRating ? performanceRating.bioValue : 0,
                    scaledScore: scaledScore ? scaledScore.bioValue : 0,
                    growth: growth ? growth.bioValue : 0,
                });
            });
        },
    },
};
</script>
