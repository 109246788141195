<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Attendance History
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body kt-margin-t-0 kt-padding-t-0">
        <div class="kt-widget29">
            <div
                v-for="item in items"
                :key="item.schoolYear"
                class="kt-widget29__content px-3 pb-0 pt-2"
            >
                <h3 class="kt-widget29__title pb-0 kt-font-lg py-2" style="border-bottom: 1px solid #ebedf2">
                    {{ item.schoolYear }}
                </h3>
                <div class="kt-widget29__item">
                    <div class="kt-widget29__info">
                        <span class="kt-widget29__stats kt-font-success">
                            {{ item.present }}
                        </span>
                        <span class="kt-widget29__subtitle">Present</span>
                    </div>
                    <div class="kt-widget29__info">
                        <span class="kt-widget29__stats kt-font-danger">
                            {{ item.absent }}
                        </span>
                        <span class="kt-widget29__subtitle">Absent</span>
                    </div>
                    <div class="kt-widget29__info">
                        <span class="kt-widget29__stats kt-font-bold pull-right">
                            <span
                                class="btn btn-bold btn-lg btn-font-lg p-3"
                                :class="`btn-label-${item.className}`"
                            >
                                {{ item.percentage }}%
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: 'AttendanceHistory',
    props: {
        evaluations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            items: [],
        };
    },
    computed: {},
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;

            const keys = [];
            v.evaluations.forEach((ev) => {
                if (ev.bioCategory == 'Attendance') {
                    const key = parseInt(ev.schoolYear, 10);
                    if (keys.indexOf(key) == -1) keys.push(key);
                }
            });

            keys.sort().reverse();
            keys.forEach((key) => {
                let className = 'danger';
                let percentage = v.evaluations.find((e) => e.bioTitle == 'Attendance Percentage' && e.schoolYear == key).bioValue || 0;
                if (percentage) percentage = parseFloat(percentage);

                if (percentage > 98) {
                    className = 'success';
                } else if (percentage > 90) {
                    className = 'danger';
                }

                const object = {
                    className,
                    percentage,
                    absent: 0,
                    schoolYear: `${key} - ${key + 1}`,
                    present: v.evaluations.find((e) => e.bioTitle == 'Total Present' && e.schoolYear == key).bioValue || 0,
                    total: v.evaluations.find((e) => e.bioTitle == 'Total Present & Absent' && e.schoolYear == key).bioValue || 0,
                };
                if (object.present) object.present = parseInt(object.present, 10);
                if (object.total) object.total = parseInt(object.total, 10);
                if (object.present && object.total) object.absent = object.total - object.present;

                v.items.push(object);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kt-portlet__body {
    max-height: 400px;
    overflow: scroll;
}
</style>
