<template>
<div class="kt-widget28__tab-item">
    <span>{{ item.bioTitle }}</span>
    <template v-if="item.bioValue == 'true' || item.bioValue == 'false'">
        <span class="kt-switch kt-switch--icon item-value">
            <label>
                <input
                    type="checkbox"
                    :checked="item.bioValue == 'true'"
                    disabled="disabled"
                >
                <span />
            </label>
        </span>
    </template>
    <span v-else-if="item.bioCategory === 'Special Education Program' && typeof item.bioValue === 'string' && item.bioValue.includes(';')">
        <li
            v-for="(value, idx) in item.bioValue.split(';')"
            :key="`bioValue_split_${idx}`"
            @click.stop.prevent="copyTextToClipboard(item.bioValue)"
        >
            <span>{{ value }}</span>
        </li>
    </span>
    <span
        v-else
        class="item-value"
        @click.stop.prevent="copyTextToClipboard(item.bioValue)"
    >{{ item.bioValue }}</span>
</div>
</template>

<script lang="ts">
export default {
    name: 'GenericDetail',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>

.kt-widget28__tab-item {
    &:first-child {
        margin-top: 1.2rem !important;
    }
    border-bottom: none !important;
}
.kt-widget28__tab-item span {
  cursor: pointer;
}
.item-value::after{
    content: '';
    display: block;
    border-bottom: 1px solid #ebedf2;
    position: relative;
    top: 1rem;
}
</style>
