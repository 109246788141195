var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c(
        "div",
        { staticClass: "kt-widget4" },
        _vm._l(_vm.items, function (exam) {
          return _c(
            "div",
            { key: exam.schoolYear, staticClass: "kt-widget4__item" },
            [
              _c("span", { staticClass: "kt-widget4__icon" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        value: "Performance Rating",
                        expression: "'Performance Rating'",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "kt-badge kt-badge--xl",
                    class: `kt-badge--${exam.className}`,
                  },
                  [_vm._v(_vm._s(exam.performanceRating))]
                ),
              ]),
              _c("p", { staticClass: "kt-font-bold mt-3" }, [
                _c("span", [_vm._v(_vm._s(exam.schoolYear))]),
                _c("br"),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Performance Level " + _vm._s(exam.performanceLevel)),
                ]),
              ]),
              exam.growth
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.topright",
                          value: "Growth Percentile",
                          expression: "'Growth Percentile'",
                          modifiers: { hover: true, topright: true },
                        },
                      ],
                      staticClass: "kt-widget4__number kt-font-info pull-right",
                      staticStyle: { width: "auto" },
                    },
                    [_vm._v(" +" + _vm._s(exam.growth) + " ")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.rightbottom",
                      value: "Scaled Score",
                      expression: "'Scaled Score'",
                      modifiers: { hover: true, rightbottom: true },
                    },
                  ],
                  staticClass: "kt-widget4__number",
                  class: `kt-font-${exam.className}`,
                  staticStyle: { width: "auto" },
                },
                [_vm._v(" " + _vm._s(exam.scaledScore) + " ")]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }