<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                High School Credits
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-widget1">
            <div
                v-for="credits in items"
                :key="credits.subject"
                class="kt-widget1__item"
            >
                <div
                    v-b-tooltip.hover.bottomright="credits.required ? `${credits.required} credits required in ${credits.subject} to graduate` : ''"
                    class="kt-widget1__info"
                >
                    <h3 class="kt-widget1__title">
                        {{ credits.subject }}
                    </h3>
                    <span
                        class="kt-widget1__desc"
                        :class="`kt-font-${credits.className}`"
                    >{{ credits.subtitle }}</span>
                </div>
                <span
                    v-b-tooltip.hover="`${credits.earned} credits earned out of ${credits.attempted} credits attempted`"
                    class="kt-widget1__number"
                    :class="`kt-font-${credits.className}`"
                >
                    {{ credits.earned }} of {{ credits.required }}
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: 'Credits',
    props: {
        evaluations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;

            const evaluations = v.evaluations.filter((ev) => ev.bioCategory == 'High School Credits');

            const subjects = [
                { name: 'English / ESL', required: 8 },
                { name: 'Mathematics', required: 6 },
                { name: 'Science', required: 6 },
                { name: 'Social Studies', required: 8 },
                { name: 'Other', required: 0 },
            ];

            subjects.forEach((subject) => {
                const earned = evaluations.find((e) => e.bioTitle == `HS ${subject.name} Credits Earned`);
                const attempted = evaluations.find((e) => e.bioTitle == `HS ${subject.name} Credits Attempted`);
                const object = {
                    subject: subject.name,
                    required: subject.required,
                    earned: earned ? earned.bioValue : 0,
                    attempted: attempted ? attempted.bioValue : 0,
                };

                if (object.earned) object.earned = parseFloat(object.earned);
                if (object.attempted) object.attempted = parseFloat(object.attempted);

                let className = '';
                if (object.earned != object.attempted) {
                    className = 'warning';
                } else {
                    className = 'success';
                }
                if (object.earned >= object.required) {
                    className = 'primary';
                }

                if (object.required == 0) className = '';

                object.className = className;
                object.subtitle = '';
                if (object.required) {
                    if (object.earned >= object.required) {
                        object.subtitle = 'Graduation requirement satisfied';
                    } else {
                        object.subtitle = `Needs ${object.required - object.earned} additional to graduate`;
                    }
                }

                v.items.push(object);
            });
        },
    },
};
</script>
