<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Promotion
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-widget1 p-0 pb-5">
            <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">
                        Entered 9th Grade
                    </h3>
                    <template v-if="yearsBehindCohort">
                        <span
                            v-if="yearsBehindCohort == 0"
                            class="kt-widget1__desc kt-font-success"
                        >On promotional track with cohort</span>
                        <span
                            v-if="yearsBehindCohort == 1"
                            class="kt-widget1__desc kt-font-warning"
                        >{{ yearsBehindCohort }} grade level behind cohort</span>
                        <span
                            v-if="yearsBehindCohort > 1"
                            class="kt-widget1__desc kt-font-danger"
                        >{{ yearsBehindCohort }} grade levels behind cohort</span>
                    </template>
                </div>
                <span class="kt-widget1__number kt-font-brand">{{ cohort }}</span>
            </div>
            <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">
                        Current Grade Level
                    </h3>
                    <span class="kt-widget1__desc" />
                </div>
                <span class="kt-widget1__number kt-font-info">{{ gradeLevel }}</span>
            </div>
        </div>
        <div class="kt-widget6">
            <div class="kt-widget6__head">
                <div class="kt-widget6__item">
                    <span class="kt-font-bold">Year</span>
                    <span class="kt-font-bold">Grade</span>
                    <span class="kt-font-bold">Status</span>
                </div>
            </div>
            <div class="kt-widget6__body">
                <div
                    v-for="(item, idx) in items"
                    :key="`year_${item.schoolYear}_${idx}`"
                    class="kt-widget6__item"
                >
                    <span>{{ item.schoolYear }}</span>
                    <span>{{ item.bioValue }}</span>
                    <span
                        class="kt-font-bold"
                        :class="'kt-font-' + item.className"
                    >{{ item.status }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';

export default {
    name: 'Promotion',
    props: {
        evaluations: {
            type: Array,
            required: true,
        },
        studentEnrollmentId: {
            type: String,
            required: true,
        },
        studentBio: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            cohort: null,
            gradeLevel: null,
        };
    },
    computed: mapState({
        user: (state) => state.user,
        yearsBehindCohort(state) {
            const v = this;
            if (!v.isNumeric(v.cohort)) return null;
            if (!v.isNumeric(v.gradeLevel)) return null;
            const currentYear = parseInt(state.user.school.schoolYear, 10); // 2020
            const cohortYear = parseInt(v.cohort, 10); // 2016
            const gradeLevel = parseInt(v.gradeLevel, 10); // 12
            const expectedGradeLevel = currentYear - cohortYear + 9; // (2020-2016) + 9 == 13
            const currentGradeLevel = parseInt(gradeLevel, 10);
            return expectedGradeLevel - currentGradeLevel;
        },
    }),
    mounted() {
        this.populate();
    },
    methods: {
        isNumeric(n) {
            return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
        },
        populate() {
            const v = this;
            if (v.studentBio.gradeLevel) v.gradeLevel = v.studentBio.gradeLevel;
            if (v.studentBio.cohortYear) v.cohort = v.studentBio.cohortYear;

            let items = v.evaluations.filter((ev) => ev.bioCategory == 'Promotion' && ev.bioValue != null && ev.bioTitle == 'Grade Level');
            if (v.gradeLevel) {
                items.push({
                    bioCategory: 'Promotion',
                    bioTitle: 'Grade Level',
                    bioValue: v.gradeLevel,
                    schoolYear: v.user.school.schoolYear,
                });
            }
            items.sort((a, b) => {
                const x = `${a.schoolYear}`;
                const y = `${b.schoolYear}`;
                if (x > y) return -1;
                if (x < y) return 1;
                return 0;
            });

            items = items.map((e) => {
                const ev = e;
                ev.status = '-';
                ev.className = 'dark';
                const repeated = items.find((x) => x.bioValue == ev.bioValue && x.schoolYear > ev.schoolYear);

                if (repeated) {
                    ev.status = 'Not Promoted';
                    ev.className = 'danger';
                } else if (v.user.school.schoolYear == ev.schoolYear) {
                    ev.status = 'In Progress';
                    ev.className = 'info';
                } else {
                    ev.status = 'Promoted';
                    ev.className = 'success';
                }
                return ev;
            });

            v.items = items;
        },
    },
};
</script>
