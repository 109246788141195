var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
      _c(
        "div",
        { staticClass: "kt-widget1" },
        _vm._l(_vm.items, function (credits) {
          return _c(
            "div",
            { key: credits.subject, staticClass: "kt-widget1__item" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottomright",
                      value: credits.required
                        ? `${credits.required} credits required in ${credits.subject} to graduate`
                        : "",
                      expression:
                        "credits.required ? `${credits.required} credits required in ${credits.subject} to graduate` : ''",
                      modifiers: { hover: true, bottomright: true },
                    },
                  ],
                  staticClass: "kt-widget1__info",
                },
                [
                  _c("h3", { staticClass: "kt-widget1__title" }, [
                    _vm._v(" " + _vm._s(credits.subject) + " "),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "kt-widget1__desc",
                      class: `kt-font-${credits.className}`,
                    },
                    [_vm._v(_vm._s(credits.subtitle))]
                  ),
                ]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: `${credits.earned} credits earned out of ${credits.attempted} credits attempted`,
                      expression:
                        "`${credits.earned} credits earned out of ${credits.attempted} credits attempted`",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "kt-widget1__number",
                  class: `kt-font-${credits.className}`,
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(credits.earned) +
                      " of " +
                      _vm._s(credits.required) +
                      " "
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" High School Credits "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }