var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
      ]),
      _vm.toolBarToggle !== null
        ? _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
            _c(
              "span",
              { staticClass: "kt-switch kt-switch--icon item-value" },
              [
                _c("label", { staticClass: "mb-0 mt-2" }, [
                  _c("input", {
                    attrs: { type: "checkbox", disabled: "disabled" },
                    domProps: { checked: _vm.toolBarToggle },
                  }),
                  _c("span"),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
      _c("div", { staticClass: "kt-widget28" }, [
        _c("div", { staticClass: "kt-widget28__wrapper" }, [
          _c("div", { staticClass: "tab-content p-3 mt-0" }, [
            _c("div", { staticClass: "tab-pane active show p-3" }, [
              _c(
                "div",
                { staticClass: "kt-widget28__tab-items row m-0 p-0" },
                _vm._l(_vm.items, function (item, index) {
                  return _c("GenericDetail", {
                    key: `${item.studentId}_${index}`,
                    class: [
                      _vm.size === "lg"
                        ? "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                        : "col-12",
                    ],
                    attrs: { item: item },
                  })
                }),
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }