<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Regents Examinations
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-widget11">
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr
                            v-for="exam in items"
                            :key="exam.subject"
                        >
                            <td style="vertical-align: middle">
                                <span
                                    class="kt-widget11__title"
                                    :class="`kt-font-${exam.className}`"
                                >{{ exam.subject }}</span>
                                <span
                                    v-if="exam.examCode"
                                    class="kt-widget11__sub"
                                >
                                    <span class="kt-font-bold">{{ exam.examCode }}</span>
                                    <span v-if="exam.examTitle"> - {{ exam.examTitle }}</span>
                                </span>
                            </td>

                            <template v-if="exam.examCode">
                                <td>
                                    <span class="kt-widget11__sub kt-font-bold">{{ exam.examYear }}</span>
                                    <span class="kt-widget11__sub">Term {{ exam.examTerm }}</span>
                                </td>
                                <td style="vertical-align: middle; text-align: center">
                                    <span
                                        v-b-tooltip.hover="'Exam Grade'"
                                        class="kt-badge kt-badge--lg kt-font-bolder"
                                        :class="`kt-badge--${exam.className}`"
                                        style="font-size: 1.1rem"
                                    >
                                        {{ exam.grade }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        v-b-tooltip.hover="'Exam Status'"
                                        class="kt-badge kt-badge--inline kt-badge--lg kt-badge kt-badge--bold pull-right"
                                        :class="`kt-badge--${exam.className} kt-badge--unified-${exam.className}`"
                                        style="vertical-align: middle"
                                    >
                                        {{ exam.status }}
                                    </span>
                                </td>
                            </template>

                            <template v-else>
                                <td style="vertical-align: middle">
                                    <span class="kt-widget11__sub kt-font-bold">-</span>
                                </td>

                                <td style="vertical-align: middle; text-align: center">
                                    <span class="kt-widget11__sub kt-font-bold">-</span>
                                </td>
                                <td>
                                    <span
                                        style="vertical-align: middle"
                                        class="kt-badge kt-badge--success kt-badge--inline kt-badge--lg kt-badge kt-badge--unified-dark kt-badge--bold pull-right"
                                    >Not Tested</span>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: 'RegentsExams',
    props: {
        evaluations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;
            const subjects = ['English Language Arts (ELA)', 'Mathematics', 'Science', 'Global Studies', 'US History'];
            subjects.forEach((subject) => {
                const x = v.evaluations.find((e) => e.bioTitle == `${subject} Status`);
                const status = x ? x.bioValue : '';
                let className = 'dark';
                if (status == 'Regents (85-100)') className = 'primary';
                if (status == 'Regents (65-84)') className = 'success';
                if (status == 'Regents (55-64)') className = 'warning';
                if (status == 'Regent Fail') className = 'danger';

                const examCode = v.evaluations.find((e) => e.bioTitle == `${subject} Exam Code`);
                const examTitle = v.evaluations.find((e) => e.bioTitle == `${subject} Exam Title`);
                const examYear = v.evaluations.find((e) => e.bioTitle == `${subject} Exam Year`);
                const grade = v.evaluations.find((e) => e.bioTitle == `${subject} Grade`);
                const examTerm = v.evaluations.find((e) => e.bioTitle == `${subject} Term`);

                v.items.push({
                    subject,
                    className,
                    status,
                    examCode: examCode ? examCode.bioValue : '',
                    examTitle: examTitle ? examTitle.bioValue : '',
                    examYear: examYear ? examYear.bioValue : '',
                    grade: grade ? grade.bioValue : '',
                    examTerm: examTerm ? examTerm.bioValue : '',
                });
            });
        },
    },
};
</script>
