<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ title }}
            </h3>
        </div>
        <div v-if="toolBarToggle !== null" class="kt-portlet__head-toolbar">
            <span class="kt-switch kt-switch--icon item-value">
                <label class="mb-0 mt-2">
                    <input
                        type="checkbox"
                        :checked="toolBarToggle"
                        disabled="disabled"
                    >
                    <span />
                </label>
            </span>
        </div>
    </div>

    <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-widget28">
            <div class="kt-widget28__wrapper">
                <div class="tab-content p-3 mt-0">
                    <div class="tab-pane active show p-3">
                        <div class="kt-widget28__tab-items row m-0 p-0">
                            <GenericDetail
                                v-for="(item, index) in items"
                                :key="`${item.studentId}_${index}`"
                                :class="[
                                    size==='lg' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3' : 'col-12' ,
                                ]"
                                :item="item"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import GenericDetail from './GenericDetail.vue';

export default {
    name: 'GenericInformation',
    components: {
        GenericDetail,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        bioCategory: {
            type: String,
            required: true,
        },
        studentBio: {
            type: Object,
            required: true,
        },
        evaluations: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: '',
        },
        toolBarToggle: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        items() {
            const { evaluations, bioCategory, user } = this;
            const { title, studentBio, shouldAppear } = this;
            const items = evaluations
                .filter((ev) => ev.bioCategory == bioCategory && ev.bioValue != null)
                .map((ev) => {
                    const evaluation = ev;
                    evaluation.bioValue = evaluation.bioValue == null ? false : evaluation.bioValue;
                    if (evaluation.bioCategory === 'Special Education Program' && typeof evaluation.bioValue === 'string') {
                        evaluation.bioValue = evaluation.bioValue.replaceAll('�', '');
                    }
                    return evaluation;
                })
                .filter((ev) => shouldAppear(ev.bioTitle, 'Label', user.school.externalDistrictId))
                .sort((a, b) => {
                    // push boolean to top of list
                    const str1 = `${a.bioValue == 'true' || a.bioValue == 'false' ? '0' : '1'}_${a.bioCategory}`;
                    const str2 = `${b.bioValue == 'true' || b.bioValue == 'false' ? '0' : '1'}_${b.bioCategory}`;
                    if (str1 < str2) return -1;
                    if (str1 > str2) return 1;
                    return 0;
                });

            // if (title === 'Biographical Information') {
            //     if (studentBio.englishLearner !== true) {
            //         items.push({
            //             bioTitle: 'English Language Learner',
            //             bioValue: 'false',
            //         });
            //     }
            //     if (studentBio.specialEd !== true) {
            //         items.push({
            //             bioTitle: 'Students with Disabilities',
            //             bioValue: 'false',
            //         });
            //     }
            // }
            return items;
        },
    },
    mounted() {
    },
    methods: {
        populate() {
        },
    },
};
</script>
<style scoped>
    .border-item {
        border-bottom: none !important;
    }
    .border-detail-item {
        border-bottom: 1px solid kt-base-color(grey, 2);
    }
</style>
