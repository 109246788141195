var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "kt-portlet__body kt-margin-t-0 kt-padding-t-0" },
      [
        _c(
          "div",
          { staticClass: "kt-widget29" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: item.schoolYear,
                staticClass: "kt-widget29__content px-3 pb-0 pt-2",
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "kt-widget29__title pb-0 kt-font-lg py-2",
                    staticStyle: { "border-bottom": "1px solid #ebedf2" },
                  },
                  [_vm._v(" " + _vm._s(item.schoolYear) + " ")]
                ),
                _c("div", { staticClass: "kt-widget29__item" }, [
                  _c("div", { staticClass: "kt-widget29__info" }, [
                    _c(
                      "span",
                      { staticClass: "kt-widget29__stats kt-font-success" },
                      [_vm._v(" " + _vm._s(item.present) + " ")]
                    ),
                    _c("span", { staticClass: "kt-widget29__subtitle" }, [
                      _vm._v("Present"),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-widget29__info" }, [
                    _c(
                      "span",
                      { staticClass: "kt-widget29__stats kt-font-danger" },
                      [_vm._v(" " + _vm._s(item.absent) + " ")]
                    ),
                    _c("span", { staticClass: "kt-widget29__subtitle" }, [
                      _vm._v("Absent"),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-widget29__info" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-widget29__stats kt-font-bold pull-right",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "btn btn-bold btn-lg btn-font-lg p-3",
                            class: `btn-label-${item.className}`,
                          },
                          [_vm._v(" " + _vm._s(item.percentage) + "% ")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Attendance History "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }