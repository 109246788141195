<template>
<div :key="`key_${title}_${key}`">
    <StudentSubHeader>
        <template #buttons />
    </StudentSubHeader>
    <div v-if="isPortfolioReady" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <StudentHeaderPortlet v-if="viewable" :display-wide="true" />

        <div v-if="viewable" class="row">
            <div v-if="noData" class="col-12">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <div class="kt-section__body">
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-outline-info m-0 w-100">
                                    <div class="alert-icon">
                                        <i class="flaticon-warning" />
                                    </div>
                                    <div class="alert-text">
                                        {{ user.school.externalDistrictId == 'NYC' && $_userMixins_isSchoolUser
                                            ? 'Please upload a RESI csv from ATS to populate this section'
                                            : 'There is no score card data available'
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="col-12">
                <div v-if="viewable" class="row w-100">
                    <div v-if="biographicEvaluations.length && shouldShowPii" class="col-12">
                        <GenericInformation
                            :evaluations="evaluations"
                            title="Biographical Information"
                            :student-bio="student"
                            bio-category="Biographical"
                            size="lg"
                        />
                    </div>
                    <template v-if="shouldShowPii">
                        <div
                            v-if="student.englishLearner"
                            :class="student.englishLearner && student.specialEd ? 'col-sm-12 col-md-6 col-lg-6 col-xl-6' : 'col-12'"
                        >
                            <GenericInformation
                                :evaluations="evaluations"
                                title="English Language Learners"
                                :student-bio="student"
                                bio-category="English Language Learners"
                                size="md"
                                :tool-bar-toggle="student.englishLearner"
                            />
                        </div>
                        <div
                            v-if="student.specialEd"
                            :class="student.englishLearner && student.specialEd ? 'col-sm-12 col-md-6 col-lg-6 col-xl-6' : 'col-12'"
                        >
                            <GenericInformation
                                :evaluations="evaluations"
                                title="Students with Disabilities"
                                :student-bio="student"
                                bio-category="Special Education Program"
                                size="md"
                                :tool-bar-toggle="student.specialEd"
                            />
                        </div>
                    </template>
                </div>
                <div v-if="examEvaluations.length" class="row w-100">
                    <div class="col-12">
                        <RegentsExams :evaluations="evaluations" />
                    </div>
                </div>
                <div class="row w-100">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <PerformanceLevels
                            :evaluations="evaluations"
                            subject="Math"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <PerformanceLevels
                            :evaluations="evaluations"
                            subject="Reading"
                        />
                    </div>
                </div>
                <div v-if="creditEvaluations.length || attendanceEvaluations.length" class="row w-100">
                    <div v-if="creditEvaluations.length" class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <Credits :evaluations="evaluations" />
                    </div>

                    <div v-if="attendanceEvaluations.length" class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <AttendanceHistory :evaluations="attendanceEvaluations" />
                    </div>
                </div>
                <div v-if="promotionEvaluations.length" class="row w-100">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <Promotion
                            :evaluations="evaluations"
                            :student-bio="student"
                            :student-enrollment-id="studentEnrollmentId"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import userMixins from '../store/mixins/userMixins';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import PerformanceLevels from './studentEvaluation/PerformanceLevels.vue';
import AttendanceHistory from './studentEvaluation/AttendanceHistory.vue';
import GenericInformation from './studentEvaluation/GenericInformation.vue';
import Promotion from './studentEvaluation/Promotion.vue';
import RegentsExams from './studentEvaluation/RegentsExams.vue';
import Credits from './studentEvaluation/Credits.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import StudentSubHeader from './StudentSubHeader.vue';

export default {
    name: 'StudentEvaluation',
    components: {
        StudentSubHeader,
        AttendanceHistory,
        Credits,
        GenericInformation,
        PerformanceLevels,
        Promotion,
        RegentsExams,
        StudentHeaderPortlet,
    },
    mixins: [portfolioMixins, studentMixins, userMixins],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean((this.$store.state.database.cache).find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        shouldShowPii() {
            const { isStudentView } = this;
            if (isStudentView) return false;
            const {encryptionEnabled} = this;
            return encryptionEnabled ? false : true;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        viewable() {
            const { student } = this;
            return student.studentEnrollmentId;
        },
        user() {
            return this.$store.state.user;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        noData() {
            return this.evaluations.length == 0;
        },
        evaluations() {
            return this.studentBiographical;
        },
        enabledSections() {
            const { sections, shouldAppear } = this;
            const { externalDistrictId } = this.user.school;
            return sections.filter((section) => section.enabled && shouldAppear(section.key, 'Portlet', externalDistrictId));
        },
        categories() {
            const c = [];
            this.evaluations.forEach((e) => {
                if (c.indexOf(e.bioCategory) == -1) {
                    c.push(e.bioCategory);
                }
            });
            return c;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        studentBiographical() {
            if (!this.isPortfolioReady) return [];
            return this.$_studentMixins_getStudentBiographicals(this.student.studentId);
        },
        biographicEvaluations() {
            return this.evaluations.filter((e) => e.bioCategory == 'Biographical');
        },
        examEvaluations() {
            return this.evaluations.filter((e) => e.bioCategory == 'NYSED Regents Assessments');
        },
        performanceLevels() {
            return this.evaluations.filter((e) => e.bioCategory == 'Reading Assessment Result' || e.bioCategory == 'Math Assessment Result');
        },
        attendanceEvaluations() {
            return this.evaluations.filter((e) => e.bioCategory == 'Attendance');
        },
        creditEvaluations() {
            return this.evaluations.filter((e) => e.bioCategory == 'High School Credits');
        },
        promotionEvaluations() {
            return this.evaluations.filter((e) => e.bioCategory == 'Promotion');
        },
    },
    watch: {
        '$route.params.studentEnrollmentId': {
            handler() {
                this.key += 1;
            },
        },
    },
    mounted() {
    },
    methods: {
        enabledRow(row) {
            const v = this;
            return Boolean(v.enabledSections.find((s) => s.row === row));
        },
        scrollTo(row) {
            const element = document.querySelector(`#${row}`);
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 90;
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth', // smooth scroll
            });
        },
        getItemsForCategory(category) {
            return this.evaluations.filter((e) => e.bioCategory == category);
        },
    },
};
</script>
