var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("StudentSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isPortfolioReady
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _vm.viewable
                ? _c("StudentHeaderPortlet", {
                    attrs: { "display-wide": true },
                  })
                : _vm._e(),
              _vm.viewable
                ? _c("div", { staticClass: "row" }, [
                    _vm.noData
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-portlet kt-portlet--height-fluid",
                            },
                            [
                              _c("div", { staticClass: "kt-section__body" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "alert alert-outline-info m-0 w-100",
                                      },
                                      [
                                        _vm._m(0),
                                        _c(
                                          "div",
                                          { staticClass: "alert-text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.user.school
                                                    .externalDistrictId ==
                                                    "NYC" &&
                                                    _vm.$_userMixins_isSchoolUser
                                                    ? "Please upload a RESI csv from ATS to populate this section"
                                                    : "There is no score card data available"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "col-12" }, [
                          _vm.viewable
                            ? _c(
                                "div",
                                { staticClass: "row w-100" },
                                [
                                  _vm.biographicEvaluations.length &&
                                  _vm.shouldShowPii
                                    ? _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("GenericInformation", {
                                            attrs: {
                                              evaluations: _vm.evaluations,
                                              title: "Biographical Information",
                                              "student-bio": _vm.student,
                                              "bio-category": "Biographical",
                                              size: "lg",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.shouldShowPii
                                    ? [
                                        _vm.student.englishLearner
                                          ? _c(
                                              "div",
                                              {
                                                class:
                                                  _vm.student.englishLearner &&
                                                  _vm.student.specialEd
                                                    ? "col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                    : "col-12",
                                              },
                                              [
                                                _c("GenericInformation", {
                                                  attrs: {
                                                    evaluations:
                                                      _vm.evaluations,
                                                    title:
                                                      "English Language Learners",
                                                    "student-bio": _vm.student,
                                                    "bio-category":
                                                      "English Language Learners",
                                                    size: "md",
                                                    "tool-bar-toggle":
                                                      _vm.student
                                                        .englishLearner,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.student.specialEd
                                          ? _c(
                                              "div",
                                              {
                                                class:
                                                  _vm.student.englishLearner &&
                                                  _vm.student.specialEd
                                                    ? "col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                    : "col-12",
                                              },
                                              [
                                                _c("GenericInformation", {
                                                  attrs: {
                                                    evaluations:
                                                      _vm.evaluations,
                                                    title:
                                                      "Students with Disabilities",
                                                    "student-bio": _vm.student,
                                                    "bio-category":
                                                      "Special Education Program",
                                                    size: "md",
                                                    "tool-bar-toggle":
                                                      _vm.student.specialEd,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.examEvaluations.length
                            ? _c("div", { staticClass: "row w-100" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("RegentsExams", {
                                      attrs: { evaluations: _vm.evaluations },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "row w-100" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-12 col-md-12 col-lg-12 col-xl-6",
                              },
                              [
                                _c("PerformanceLevels", {
                                  attrs: {
                                    evaluations: _vm.evaluations,
                                    subject: "Math",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-12 col-md-12 col-lg-12 col-xl-6",
                              },
                              [
                                _c("PerformanceLevels", {
                                  attrs: {
                                    evaluations: _vm.evaluations,
                                    subject: "Reading",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.creditEvaluations.length ||
                          _vm.attendanceEvaluations.length
                            ? _c("div", { staticClass: "row w-100" }, [
                                _vm.creditEvaluations.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-sm-12 col-md-12 col-lg-12 col-xl-6",
                                      },
                                      [
                                        _c("Credits", {
                                          attrs: {
                                            evaluations: _vm.evaluations,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.attendanceEvaluations.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-sm-12 col-md-12 col-lg-12 col-xl-6",
                                      },
                                      [
                                        _c("AttendanceHistory", {
                                          attrs: {
                                            evaluations:
                                              _vm.attendanceEvaluations,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.promotionEvaluations.length
                            ? _c("div", { staticClass: "row w-100" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                  },
                                  [
                                    _c("Promotion", {
                                      attrs: {
                                        evaluations: _vm.evaluations,
                                        "student-bio": _vm.student,
                                        "student-enrollment-id":
                                          _vm.studentEnrollmentId,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }