var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-widget28__tab-item" },
    [
      _c("span", [_vm._v(_vm._s(_vm.item.bioTitle))]),
      _vm.item.bioValue == "true" || _vm.item.bioValue == "false"
        ? [
            _c(
              "span",
              { staticClass: "kt-switch kt-switch--icon item-value" },
              [
                _c("label", [
                  _c("input", {
                    attrs: { type: "checkbox", disabled: "disabled" },
                    domProps: { checked: _vm.item.bioValue == "true" },
                  }),
                  _c("span"),
                ]),
              ]
            ),
          ]
        : _vm.item.bioCategory === "Special Education Program" &&
          typeof _vm.item.bioValue === "string" &&
          _vm.item.bioValue.includes(";")
        ? _c(
            "span",
            _vm._l(_vm.item.bioValue.split(";"), function (value, idx) {
              return _c(
                "li",
                {
                  key: `bioValue_split_${idx}`,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.copyTextToClipboard(_vm.item.bioValue)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(value))])]
              )
            }),
            0
          )
        : _c(
            "span",
            {
              staticClass: "item-value",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.copyTextToClipboard(_vm.item.bioValue)
                },
              },
            },
            [_vm._v(_vm._s(_vm.item.bioValue))]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }