var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-widget1 p-0 pb-5" }, [
        _c("div", { staticClass: "kt-widget1__item" }, [
          _c(
            "div",
            { staticClass: "kt-widget1__info" },
            [
              _c("h3", { staticClass: "kt-widget1__title" }, [
                _vm._v(" Entered 9th Grade "),
              ]),
              _vm.yearsBehindCohort
                ? [
                    _vm.yearsBehindCohort == 0
                      ? _c(
                          "span",
                          { staticClass: "kt-widget1__desc kt-font-success" },
                          [_vm._v("On promotional track with cohort")]
                        )
                      : _vm._e(),
                    _vm.yearsBehindCohort == 1
                      ? _c(
                          "span",
                          { staticClass: "kt-widget1__desc kt-font-warning" },
                          [
                            _vm._v(
                              _vm._s(_vm.yearsBehindCohort) +
                                " grade level behind cohort"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.yearsBehindCohort > 1
                      ? _c(
                          "span",
                          { staticClass: "kt-widget1__desc kt-font-danger" },
                          [
                            _vm._v(
                              _vm._s(_vm.yearsBehindCohort) +
                                " grade levels behind cohort"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("span", { staticClass: "kt-widget1__number kt-font-brand" }, [
            _vm._v(_vm._s(_vm.cohort)),
          ]),
        ]),
        _c("div", { staticClass: "kt-widget1__item" }, [
          _vm._m(1),
          _c("span", { staticClass: "kt-widget1__number kt-font-info" }, [
            _vm._v(_vm._s(_vm.gradeLevel)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "kt-widget6" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "kt-widget6__body" },
          _vm._l(_vm.items, function (item, idx) {
            return _c(
              "div",
              {
                key: `year_${item.schoolYear}_${idx}`,
                staticClass: "kt-widget6__item",
              },
              [
                _c("span", [_vm._v(_vm._s(item.schoolYear))]),
                _c("span", [_vm._v(_vm._s(item.bioValue))]),
                _c(
                  "span",
                  {
                    staticClass: "kt-font-bold",
                    class: "kt-font-" + item.className,
                  },
                  [_vm._v(_vm._s(item.status))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Promotion "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [
        _vm._v(" Current Grade Level "),
      ]),
      _c("span", { staticClass: "kt-widget1__desc" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget6__head" }, [
      _c("div", { staticClass: "kt-widget6__item" }, [
        _c("span", { staticClass: "kt-font-bold" }, [_vm._v("Year")]),
        _c("span", { staticClass: "kt-font-bold" }, [_vm._v("Grade")]),
        _c("span", { staticClass: "kt-font-bold" }, [_vm._v("Status")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }